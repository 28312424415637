<template>
    <div>
        <slot></slot>
    </div>
</template>


<style scoped>

div {
    margin-top: 8px;
    padding: 0 0px;
    height: fit-content;
    width: 100%;
    background-color: rgba(64, 167, 98, 0);
    border-radius: 5px;
}

</style>