<template>
    <div class="boxy" role="alert">
        <strong>Mountable chapters</strong> 
        <div class="text">Only directly mountable chapters will appear here, and it will dynamically change according to the last chapter mounted in your storyline. Click one to mount it.
        </div>
    </div>
</template>

<style scoped>
.boxy {
    background-color: #cff4fc;
    border-left: #00d5ff 6px solid;
    color: #055160;
    border-radius: 3px;
    opacity: 0.9;
    padding: 5px;
    margin: 10px 0px;
}

.text {
    font-size: 15px;
    margin-top: 3px;
}

.margin1 {
    margin-right: 12px;
}
</style>