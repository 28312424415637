<template>
    <div class="boxy" role="alert">
        <strong>Tags </strong> 
        <span class="text">Select suitable tags to show on your story</span>
    </div>
</template>

<style scoped>
.boxy {
    background-color: #cff4fc;
    border-left: #00d5ff 6px solid;
    color: #055160;
    border-radius: 3px;
    opacity: 0.9;
    padding: 5px;
    margin: 15px 1px 5px 0px;
}

.text {
    font-size: 15px;
    margin-top: 3px;
}

.margin1 {
    margin-right: 12px;
}
</style>