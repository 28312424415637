<template>
    <div class="boxy" role="alert">
        <strong>How to <u>mount</u> and <u>unmount</u> chapters</strong> 
        <div class="text">Look for a chapter in the chapters section (bottom) and click one to mount it. To unmount, click on "Unmount last" button.</div>
    </div>
</template>

<style scoped>
.boxy {
    background-color: #cff4fc;
    border-left: #00d5ff 6px solid;
    color: #055160;
    border-radius: 3px;
    opacity: 0.9;
    padding: 5px;
    margin: 0 1px 8px 0px;
}

.text {
    font-size: 15px;
    margin-top: 3px;
}

.margin1 {
    margin-right: 12px;
}
</style>